export default [
                      
  { name: '<span class="bold">城味</span><span>預見鳳鳴</span>', imgSrc: '', subTitle: '', section: 'section2', svgSrc: '', offset: 0 },
  { name: '<span class="bold">尋味</span><span>宜居優境</span>', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '', mobileOffset: 0 },
  { name: '<span class="bold">對味</span><span>建築詠賦</span>', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '', mobileOffset: 0 },
  { name: '<span class="bold">品味</span><span>精質內涵</span>', imgSrc: '', subTitle: '', section: 'section9', svgSrc: '', mobileOffset: 0 },
  // { name: '最WE Style的家', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '', mobileOffset: 0 },
  // { name: '立即來電', imgSrc: '', subTitle: '', section: 'contact-info', svgSrc: '', mobileOffset: 0, type: 'btn', color: 'orange' },
  // { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '', mobileOffset: 0, type: 'btn', color: 'blue' },
  // { name: '立即預約', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', mobileOffset: 0, type: 'btn', color: 'green' },
]
